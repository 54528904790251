<template>
  <b-card> </b-card>
</template>

<script>
import { BRow, BCol, BImg, BCard, BButton } from 'bootstrap-vue'

export default {
  name: 'NotesAndReceipts',
  components: {
    BRow,
    BCol,
    BImg,
    BCard,
    BButton,
  },
  data() {
    return {}
  },
  async mounted() {},
  methods: {},
}
</script>
